<template>
<el-dialog
    :title="title"
    :visible.sync="isShow"
    width="600px"
    :before-close="closeFun">
    <div>
    <el-form ref="form" :model="form" label-width="80px">
        <el-form-item prop="checkReason">
          <el-input type="textarea" v-model="form.checkReason" :rows="5" maxlength="100" show-word-limit placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="checkbox" v-if="!type">
          <el-checkbox-group v-model="form.checkbox">
            <el-checkbox v-for="(item, idx) in checkboxData" :key="item" :label="idx">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
    </el-form>
    </div>
    <div slot="footer">
    <el-button @click="closeFun">取 消</el-button>
    <el-button type="primary" @click="confirmFun">确 定</el-button>
    </div>
</el-dialog>
</template>

<script>
export default {
  name: 'CheckModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    id:{
      type: [String, Number],
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        checkReason: '',
        checkbox: []
      },
      checkboxData: ['商户已缴纳罚金', '罚金收据已开']
    }
  },
  methods: {
    closeFun () {
      this.$refs.form && this.$refs.form.resetFields()
      this.form = {
        checkReason: '',
        checkbox: []
      }
      this.$emit('update:isShow', false)
    },
    confirmFun () {
      const { checkReason, checkbox } = this.form
      const type = this.type
      if(type) {
        if(!checkReason) return this.$message.warning('请输入理由')
      }else {
        if(!checkReason && !checkbox.length) return this.$message.warning('请输入理由或者选择理由')
      }
      this.$refs.form.validate(valid => {
        if(valid) {
          const data = type ? {
            id: Number(this.id),
            reason: checkReason
          } : {
            checkReason,
            id: Number(this.id),
            checkReasonPaid: Number(checkbox.includes(0)),
            checkReasonReceipt: Number(checkbox.includes(1))
          }
          const api = type ? 'cancelTickApi' : 'checkTicketApi'
          this.$api.merchant[api](data).then(({ code, msg }) => {
            if(code !== 200) return this.$message.warning(msg)
            this.$message.success(msg)
            this.$listeners.callback && this.$emit('callback')
            this.closeFun()
          })
        }
      })
    },
  }
}
</script>

<style>

</style>