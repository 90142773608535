<template>
  <div class="ticket" v-if="$route.path==='/businessModule/merchant/ticket' || $route.path === '/businessModule/merchant/verify'">
    <d-search
      :searchData="searchData"
      @searchEvent="searchEvent"
      @resetEvent="resetEvent"
      @exportEvent="exportEvent"
    />
    <div class="content">
      <div class="header" v-if="type !== 'verify'">
        <el-button type="primary" size="small" @click="addFun">新增罚单</el-button>
      </div>
      <d-lable class="contentTable"
              :tableData='tableData'
              :columnData='columnData'>
        <template v-slot:status="{ row }">
          <el-button :type="$map.ticketBtnColor[row.status]" @click="scanFun(row)">{{ $map.ticketStatus[row.status] }}</el-button> 
        </template>
        <template v-slot:action="{ row }">
          <span class="mgr10 blue" type="text" @click="scanFun(row)">查看</span> 
          <span class="mgr10 blue" @click="checkFun(row)" v-if="type === 'verify' && row.status === 1">核验</span> 
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager"
              @change="getItemList" />
    <CheckModal :isShow.sync="isShow" :id="id" @callback="getItemList"/>
  </div>
  <router-view v-else/>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import CheckModal from './components/check-modal.vue'
import { onDownload, filterDayDate, disabledDayDate } from "@/utils/utils";
export default {
  name: "Ticket",
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    DSearch,
    DLable,
    DPaging,
    CheckModal
  },
  data() {
    return {
      searchData: {
        searchItem: [
          {
            label: '选择店铺',
            value: 'shopIds',
            type: 'linkageSelect',
            dateType: 'month',
            placeholder: '',
            options: [],
            filterable: true,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'childList'
            }
          },
          {
            label: "状态",
            value: "status",
            type: "commonSelect",
            placeholder: "模糊搜索",
            options: this.$map.getter('ticketStatus')
          },
          {
            label: "时间范围",
            value: "date",
            type: "scopeDate",
            startPlaceholder:"开始日期",
            endPlaceholder:"结束日期",
            noClear: true,
            options: {
              onPick: filterDayDate.bind(this),
              disabledDate: disabledDayDate.call(this, 365*24*60*60*1000)
            }
          },
          {
            label: "罚单编号",
            value: "penalShopNo",
            type: "commonInput",
            placeholder: "模糊搜索"
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
          exportBtn: true
        },
        params: {
          shopIds: [],
          status: '',
          date: [],
          penalShopNo: ''
        },
      },
      columnData: [
        { prop: 'penalShopNo', label: '罚单编号' },
        { prop: 'penalTypeJoin', label: '罚金类型' },
        { prop: 'shop', label: '处理店铺' },
        { prop: 'penalSum', label: '罚金金额' },
        { prop: 'status', label: '状态', slotProp: true},
        { prop: 'createTime', label: '创建时间'},
        { prop: 'action', label: '操作', slotProp: true },
      ],
      tableData: [],
      maxDate: null,
      minDate: null,
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      isShow: false,
      id: ''
    };
  },
  watch: {
    '$route': {
      handler (to,from) {
        if(to.path === '/businessModule/merchant/ticket' || to.path === '/businessModule/merchant/verify') {
          this.searchEvent()
        }
      }
    }
  },
  created () {
    this.setInitDate()
    this.searchEvent()
    this.getShopList()
  },
  methods: {
    setInitDate () {
      const date = new Date()
      const endDate = this.$reconava.parseTime(date, '{y}-{m}-{d}')
      const startDate = this.$reconava.parseTime(date - 365*24*60*60*1000, '{y}-{m}-{d}')
      this.searchData.params.date = [startDate, endDate]
    },
    searchEvent() {
      this.pager.page = 1
      this.pager.rows = 10
      this.getItemList()
    },
    // 导出
    exportEvent () {
      const data = this.setParams()
      data.pageSize = -1
      this.$api.merchant.exportTicketApi(data).then(({headers,data}) => {
        onDownload(headers,data)
      })
    },
    resetEvent() {
      this.setInitDate()
      this.getItemList()
    },
    addFun () {
      this.$router.push({ path:'/businessModule/merchant/ticket/add' })
    },
    scanFun (row) {
      const currentPath = this.$route.path
      const path = currentPath === '/businessModule/merchant/ticket' ? '/businessModule/merchant/ticket/detail' : '/businessModule/merchant/verify/detail'
      this.$router.push({ path, query: { id: row.id }})
    },
    checkFun ({ id }) {
      this.id = id
      this.isShow = true
    },
    setParams () {
      const { penalShopNo, date, status, shopIds } = this.searchData.params
      const [startTime, endTime] = date || []
      return {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        startTime: startTime ? startTime + ' 00:00:00' : null,
        endTime: endTime ? endTime + ' 23:59:59' : null,
        penalShopNo,
        status,
        shopIds: shopIds[shopIds.length - 1]
      }
    },
    getItemList () {
      const data = this.setParams()
      this.$api.merchant.getTicketListApi(data).then(({ code, data}) => {
        if(code !== 200) return
        this.pager.count = data.total || 0
        this.tableData = (data.list || []).map(e => {
          e.hasChildren = true
          return e
        })
      })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
          .then(({ data, code }) => {
            if (code == 200) {
              this.childList = data.childList
              const child = [{
                id: data.id,
                name: data.name,
                childList: data.childList
              }]
              this.searchData.searchItem.forEach(ele => {
                if (ele.type === 'linkageSelect') {
                  ele.options = child
                }
              })
            }
          })
    }
  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;
  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }
  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
  }
}
.d-paging {
  text-align: center;
}
</style>